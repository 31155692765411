<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    style="width:100%;"
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Tanggal Mulai"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    style="width:100%;"
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Tanggal Akhir"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterToko
                    style="width:100%;"
                    v-model:value="state.params.toko"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%;"
                    v-model:value="state.params.area"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:provinsi="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterSalesman
                    style="width:100%;"
                    v-model:value="state.params.salesman"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="btnFetchData"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="card mt-4">
            <div class="card-body">
                <EChartCustom
                    :loading="state.chart.loading"
                    :labelx="state.chart.labelx"
                    :series="state.chart.series"
                    title="Planned vs Realisasi"/>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12">
                <strong>List Hasil Survey</strong>
            </div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    namefile="Laporan-Hasil-Survey"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="Detail">
                            <a
                                class="btn btn-sm btn-success"
                                size="small"
                                @click="() => {
                                    router.push({
                                        path: `/atsalesman/hasil-survey/${record.survey_id}`,
                                    })
                                }">
                                <i class="fe fe-info fa-lg" />&nbsp;Detail
                            </a>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import apiClient from '@/services/axios'
import FilterToko from '@/components/filter/FilterToko'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterSalesman from '@/components/filter/FilterSalesman'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import EChartCustom from '@/components/charts/EChartCustom'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        FilterToko,
        FilterRegional,
        FilterProvince,
        FilterArea,
        FilterKabupatenKota,
        FilterSalesman,
        DownloadExcel,
        EChartCustom,
    },
    setup() {
        const errorMessage = ref()
        const router = useRouter()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Tanggal Visit',
                    dataIndex: 'survey_date',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Salesman',
                    dataIndex: 'salesman',
                },
                {
                    title: 'Nomor Referensi',
                    dataIndex: 'no_survey',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: '/api/report-ats/list-hasil-survey',
            data: [
                {
                    id: 1,
                },
            ],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: extractQueryParams({
                q: '',
                toko: [],
                region: [],
                area: [],
                provinsi: [],
                kabupaten: [],
                salesman: [],
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()).endOf("month"),
                page: 1,
                "per-page": 10,
            }),
            chart: {
                loading: false,
                endpoint: '/api/report-ats/grafik-target-realisasi',
                labelx: [],
                series: [],
            },
        })

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // fetch list chart data
        const fetchDataChartList = () => {
            state.chart.loading = true

            apiClient
                .get(state.chart.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    state.chart.labelx = [{
                        type: 'category',
                        data: data.map(item => item.day),
                        axisPointer: {
                            type: 'shadow',
                        },
                    }]

                    state.chart.series = [
                        {
                            name: 'Planned',
                            type: 'bar',
                            data: data.map(item => item.target),
                        },
                        {
                            name: 'Realisasi',
                            type: 'bar',
                            data: data.map(item => item.realisasi),
                        },
                    ]
                })
                .finally(() => {
                    state.chart.loading = false
                })
        }

        const btnFetchData = () => {
            fetchDataList()
            fetchDataChartList()
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            router,
            state,
            btnFetchData,
            queryParams,
            handleTableChange,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
        }
    },
})
</script>
